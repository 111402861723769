/* You can add global styles to this file, and also import other style files */

/* TODO: Switch to FontAwesome 5.0 */

@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import 'assets/css/fonts/font-awesome/css/font-awesome.min.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';

@font-face {
  font-family: AvenirNext;
  src: url('assets/css/fonts/avenir-next/AvenirNextLTPro-Regular.otf') format('opentype');
}

/* 100 height for body */

html {
  min-height: 96%;
  height: 96%;
}

body {
  min-height: 100%;
  height: 100%;
}

header {
  padding: 20px 0 10px 0;
}

.main-wrapper {
  min-height: 100%;
  height: 100%;
}

.container-main {
  min-height: 91%;
  height: 91%;
}

.content-row {
  min-height: 85%;
  height: 90%;
}

.content-row .tab-panel {
  min-height: 93%;
}

.container-main > .row.content,
.container-main > .row.content > div,
.product-management,
.tab-panel,
.heigth-100 {
  min-height: 100%;
  height: 100%;
}

body {
  font-family: 'AvenirNext' !important;
  font-weight: 500;
}

h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

body.white-half {
  height: 100%;
}

@media (min-width: 480px) {
  body:not(.white-half) .Winery .dx-tabpanel-container {
    background-image: url(assets/images/wine-glass.png);
    background-repeat: no-repeat;
    background-position: 99% 9px;
    background-size: 25px;
  }

  body:not(.white-half) .Hotel .dx-tabpanel-container {
    background-image: url(assets/images/hotel.png);
    background-repeat: no-repeat;
    background-position: 99% 9px;
    background-size: 50px;
  }
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media (min-width: 320px) and (max-width: 480px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

h1 {
  margin: 0.67rem 0 !important;
}

/* Safari layout fix */
.row:before,
.row:after {
  display: none !important;
}

/* Safari layout fix */

a.red {
  display: inline;
  color: #990000 !important;
  text-decoration: none !important;
  transition: 0.5s ease;
}

a.red:hover {
  color: #e95f66 !important;
  text-decoration: none !important;
  transition: 0.5s ease;
}

a.red.bg-grey {
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #f0f0f0;
  transition: 0.5s ease;
}

a.red.bg-grey:hover {
  color: #990000 !important;
  background-color: #e3e3e3;
  transition: 0.5s ease;
}

.color-red {
  color: #990000 !important;
  text-decoration: none !important;
  transition: 0.5s ease;
}

.color-red:hover {
  color: #e95f66 !important;
  text-decoration: none !important;
  transition: 0.5s ease;
}

a.action-link {
  transition: 0.3s ease;
  padding: 10px 25px;
  border-radius: 25px;
  margin-right: 5px;
}

a.action-link.active,
a.action-link:hover {
  text-decoration: none;
  color: #fff !important;
  background-color: #990000;
  transition: 0.3s ease;
}

.vl {
  border-left: 1px solid #757575;
  height: 20px;
  display: inline;
}

.main-row {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 !important;
}

.side-area {
  display: none;
  position: absolute;
  width: 0%;
  right: 0%;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  transition: display 0.5s ease;
  -webkit-transition: display 0.5s ease;
}

.show.side-area {
  position: relative;
  display: block;
  height: 95%;
  width: 100%;
  transition: width 0.5s ease;
  -webkit-transition: width 0.5s ease;
  transition: display 0.5s ease;
  -webkit-transition: display 0.5s ease;
}

/* For first add */

body.white-half .grey-side {
  background-color: transparent;
}

/* For first add */

/* popup */

.popup .popup-description {
  display: inline-block;
  margin-bottom: 15px;
}

.popup .popup-content {
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

.popup .popup-buttons {
  margin-bottom: 10px;
}

.popup .popup-buttons dx-button {
  margin-left: 10px;
}

/* popup */

/* Items grid common */

.box-shadow,
.items-grid {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  -webkit-box-shadow: 1px 2px 10px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 2px 10px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 1px 2px 10px -5px rgba(0, 0, 0, 0.75);
  transition: 0.4s ease;
}

.items-grid .actions {
  margin: 0;
  padding: 0;
}

.items-grid .actions li a {
  color: #990000;
}

.items-grid .actions li {
  display: inline-block;
  margin-right: 10px;
  list-style: none;
}

.items-grid .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
}

.items-grid .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row.dx-freespace-row > td {
  padding: 0;
}

.items-grid .dx-datagrid-rowsview .dx-datagrid-table .dx-freespace-row > td,
.items-grid .dx-datagrid-rowsview .dx-datagrid-table .dx-virtual-row > td {
  padding-top: 0;
  padding-bottom: 0;
}

/* Items grid common */

.text-center {
  text-align: center;
}

.inline-block {
  display: inline;
  float: left;
  margin-right: 10px;
}

.autofill-field {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

.dx-loadindicator.loading-screen-center {
  position: fixed;
  top: 45%;
  left: 49%;
}

.dx-loadindicator.loading-box-center {
  position: absolute;
  left: 49%;
  top: 45%;
}

.dx-loadindicator.loading-parent-center {
  position: absolute;
  left: 49%;
  top: 45%;
  z-index: 9999;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

/* IOS */
@supports (-webkit-overflow-scrolling: touch) {
  .dx-selectbox.dx-texteditor.dx-editor-underlined .dx-texteditor-input {
    padding: 0 !important;
  }
}

/* @supports not (-webkit-overflow-scrolling: touch) {
    .dx-selectbox.dx-texteditor.dx-editor-underlined .dx-texteditor-input {
        padding: 0!important;
    }
} */

/* IOS */

.win-cursor-pointer {
  cursor: pointer;
}

.win-text-break {
  white-space: break-spaces;
}
