.dx-overlay-wrapper {
  z-index: 999999 !important;
}

.dx-toast-info {
  background-color: #284589;
}

.dx-toast-success {
  background-color: #91c028;
}

.dx-toast-error {
  background-color: #c92a32;
}

.dx-toast-warning {
  background-color: #cd972b;
}

.dx-datagrid-content .dx-datagrid-table.dx-datagrid-table-fixed .dx-column-indicators .dx-sort.dx-sort-none {
  display: none;
}

.dx-button {
  border-radius: 20px !important;
}

.dx-state-readonly .dx-texteditor-input {
  color: #8a8a8a;
}

/* DxDatagrid */
.dx-datagrid-header-panel {
  border-bottom: none;
}

/* Search */
.dx-list-search .dx-placeholder {
  padding-left: 25px;
}

/* Search */

/* DxDatagrid */

/* DxTextBox, DxTextArea, default input changes */

.dx-field {
  display: inline-block !important;
  margin: 0 0 20px 0 !important;
  width: 100% !important;
  /* padding-right: 15px!important; */
}

/*
.dx-field:last-of-type{
    margin: 0 0 20px 0;
} */

.dx-texteditor.dx-editor-underlined:after {
  border: none !important;
}

.dx-field-label,
.dx-field-value {
  float: none;
  width: 100%;
}

.dx-texteditor {
  border-bottom: 1px solid #ddd;
}

.simple-input,
dx-text-box .dx-texteditor-container {
  height: 38px;
  padding-top: 10px;
}

/* .dx-field.col-md-6,
.dx-field.col-md-12 {
    padding-right: 10px;
} */

.simple-input {
  width: 100%;
  background-color: transparent;
  font-size: 16px !important;
  padding: 7px 0 6px;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.simple-input:focus {
  outline: none;
}

.simple-input:before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  content: '';
  position: absolute;
  z-index: 2;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.simple-input:hover {
  border-bottom: 2px solid #990000;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
  -moz-transition: -moz-transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
  -o-transition: -o-transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1);
}

.simple-input.has-value {
  border-bottom: 2px solid #990000 !important;
}

/* DxTextBox, DxTextArea changes */

dx-text-area.dx-texteditor,
dx-text-box.dx-texteditor {
  background: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: unset !important;
}

dx-text-area.dx-texteditor.dx-state-focused .dx-placeholder,
dx-text-box.dx-texteditor.dx-state-focused .dx-placeholder {
  transition: 0.2s ease all;
}

dx-text-area.dx-texteditor:not(.dx-texteditor-empty) .dx-texteditor-container,
dx-text-box.dx-texteditor:not(.dx-texteditor-empty) .dx-texteditor-container {
  border-bottom: 2px solid #990000;
}

dx-text-area.dx-texteditor:not(.dx-texteditor-empty) .dx-placeholder,
dx-text-area.dx-texteditor.dx-state-focused .dx-placeholder,
dx-text-area.dx-texteditor.dx-state-focused .dx-placeholder.dx-state-invisible,
dx-text-box.dx-texteditor:not(.dx-texteditor-empty) .dx-placeholder,
dx-text-box.dx-texteditor.dx-state-focused .dx-placeholder,
dx-text-box.dx-texteditor.dx-state-focused .dx-placeholder.dx-state-invisible {
  top: -10px;
  left: 0;
  font-size: 12px;
  opacity: 1;
  transition: 0.2s ease all;
}

dx-text-area.dx-texteditor:not(.dx-texteditor-empty) .dx-placeholder,
dx-text-area.dx-texteditor.dx-state-focused .dx-placeholder,
dx-text-area.dx-texteditor.dx-state-focused .dx-placeholder.dx-state-invisible {
  top: -8px;
}

dx-text-area.dx-texteditor .dx-placeholder.dx-state-invisible,
dx-text-box.dx-texteditor .dx-placeholder.dx-state-invisible {
  display: block !important;
}

dx-text-area.dx-texteditor .dx-texteditor-input {
  margin-top: 15px;
}

dx-text-area.dx-texteditor .dx-texteditor-input,
dx-text-box.dx-texteditor .dx-texteditor-input {
  background: transparent !important;
}

/* DxTextBox, DxTextArea changes */

/* DxSelect changes */

dx-select-box .dx-texteditor-container {
  height: 35px;
  padding-top: 10px;
}

dx-select-box.dx-texteditor {
  background: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: unset !important;
}

dx-select-box .dx-texteditor-input {
  background: transparent !important;
  padding: 0 0 6px !important;
}

dx-select-box.dx-texteditor.dx-state-focused .dx-placeholder {
  transition: 0.2s ease all;
}

dx-select-box.dx-texteditor:not(.dx-texteditor-empty) .dx-texteditor-container {
  border-bottom: 2px solid #990000;
}

dx-select-box.dx-texteditor:not(.dx-texteditor-empty) .dx-placeholder,
dx-select-box.dx-texteditor.dx-state-focused .dx-placeholder,
dx-select-box.dx-texteditor.dx-state-focused .dx-placeholder.dx-state-invisible {
  top: -9px;
  left: 0;
  font-size: 10px;
  opacity: 1;
  transition: 0.2s ease all;
}

.dx-texteditor.dx-editor-underlined .dx-placeholder:before {
  padding: 7px 0 0px;
}

dx-select-box.dx-texteditor.dx-editor-underlined .dx-placeholder:before {
  padding: 7px 0 6px;
}

dx-select-box.dx-texteditor .dx-placeholder.dx-state-invisible {
  display: block !important;
}

dx-select-box.dx-dropdowneditor.dx-state-hover .dx-dropdowneditor-icon,
dx-select-box.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
dx-select-box.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background: transparent !important;
}

dx-select-box.dx-texteditor:after {
  content: '';
  display: block;
  border-bottom: 1px solid #990000 !important;
  width: 0;
  position: absolute;
  right: 0;
  transition: width 1s ease;
  -webkit-transition: width 1s ease;
}

dx-select-box.dx-texteditor:hover:after,
dx-select-box.dx-texteditor.dx-state-focused:after,
dx-select-box.dx-texteditor:not(.dx-texteditor-empty):after {
  width: 100%;
  left: 0;
}

/* DxSelect changes */

/* DxCalendar changes */

.dx-datebox-calendar .dx-dropdowneditor-icon,
.dx-datebox-calendar.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  width: 24px;
  height: 100%;
  font-size: 15px;
}

.dx-datebox-calendar .dx-dropdowneditor-icon:before,
.dx-datebox-calendar.dx-dropdowneditor-active .dx-dropdowneditor-icon:before {
  font-family: FontAwesome;
  content: '\f073';
  top: 60%;
}

/* DxCalendar changes */

/* DxPopover */

.dx-popup-wrapper > .dx-overlay-content {
  -webkit-box-shadow: 0px 0px 31px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 31px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 31px -10px rgba(0, 0, 0, 0.75);
}

/* DxPopover */
